import "@fontsource/montserrat"
import "@fontsource/montserrat/900.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/700.css"
import './src/styles/section.css';
import './src/styles/material/button.css'
import "./src/styles/tarteaucitron.css";
import './src/styles/navbar.css'
import './src/styles/video-js.css'
import './src/styles/quality-selector.css'
import 'react-tooltip/dist/react-tooltip.css'
import "normalize.css";
import './src/styles/global.css';
// register videojs with videojs quality selector
import videoQualityJS from '@silvermine/videojs-quality-selector';
import videojs from 'video.js';
videoQualityJS(videojs);
videoQualityJS(videojs);
