import React from "react"
import { ThemeProvider, createTheme } from "@mui/material/styles"

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      secondary: {
        main: '#FBB52D',
      },
    },
    typography: {
        fontFamily: [
          '-apple-system',
          'Montserrat',
          'Roboto',
          'sans-serif',
        ].join(','),
      },
  });

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={darkTheme}>{element}</ThemeProvider>
}